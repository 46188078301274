import React, { useRef, useState } from "react";

const ThermalPrinter = () => {
  const [printerIPAddress, setPrinterIPAddress] = useState("192.168.0.121");
  const [printerPort, setPrinterPort] = useState("8008");
  const [textToPrint, setTextToPrint] = useState("");
  const [connectionStatus, setConnectionStatus] = useState("");
  const [error,setError] = useState('')

  const ePosDevice = useRef();
  const printer = useRef();

  const STATUS_CONNECTED = "Connected";

  const connect = () => {
      try {
          setConnectionStatus("Connecting ...");

          if (!printerIPAddress) {
              setConnectionStatus("Type the printer IP address");
              return;
          }
          if (!printerPort) {
              setConnectionStatus("Type the printer port");
              return;
          }
          console.log('device', window.epson)
          setConnectionStatus("Connecting ...");

          let ePosDev = new window.epson.ePOSDevice();
          ePosDevice.current = ePosDev;

          ePosDev.connect(printerIPAddress, printerPort, (data) => {
              if (data === "OK") {
                  ePosDev.createDevice(
                      "local_printer",
                      ePosDev.DEVICE_TYPE_PRINTER,
                      {crypto: true, buffer: false},
                      (devobj, retcode) => {
                          if (retcode === "OK") {
                              printer.current = devobj;
                              setConnectionStatus(STATUS_CONNECTED);
                          } else {
                              throw retcode;
                          }
                      }
                  );
              } else {
                 setError('Connection Error')
                  try{
                     setError('Connection Error'+JSON.stringify(data))
                  }catch (e){

                     setError('Connection Error Catch')
                  }
                  // throw data;
              }
          });
      }catch (e) {
         setError('Main error')
          try {
              setError('Main error ' + e.message)
          }catch (e) {
              setError("Main error catch")
          }
         }
  };

  const print = (text) => {
      try {
          let prn = printer.current;
          if (!prn) {
              alert("Not connected to printer");
              return;
          }
          prn.addPageDirection(printer.DIRECTION_RIGHT_TO_LEFT);
          prn.addPageBegin();
          ///prn.addPageArea(0, 0, 83, 100);
          prn.addTextLang('mul');
          prn.addText('\t فاتورة ضريبية ');
          prn.addText(text)
          prn.addPageLine(0, 50, 99, 50, printer.LINE_THIN);
          prn.addFeed();
          prn.addText('1234')
          prn.addBarcode('12345', prn.BARCODE_CODE39, prn.HRI_BOTH, prn.FONT_A, 2, 32);
          prn.addFeedLine(5);
          prn.addText('text2')
          prn.addCut(prn.CUT_FEED);
          prn.send();
      }catch (e) {
      setError('Error')
          try {
              setError('Error ' + e.message)

          }catch (e) {
             setError('Error catch')
          }
      }
  };

  return (
    <div id="thermalPrinter">
      <input
        id="printerIPAddress"
        placeholder="Printer IP Address"
        value={printerIPAddress}
        onChange={(e) => setPrinterIPAddress(e.currentTarget.value)}
      />
      <input
        id="printerPort"
        placeholder="Printer Port"
        value={printerPort}
        onChange={(e) => setPrinterPort(e.currentTarget.value)}
      />
      <button
        disabled={connectionStatus === STATUS_CONNECTED}
        onClick={() => connect()}
      >
        Connect
      </button>
      <span className="status-label">{connectionStatus}</span>
      <hr />
      <textarea
        id="textToPrint"
        rows="3"
        placeholder="Text to print"
        value={textToPrint}
        onChange={(e) => setTextToPrint(e.currentTarget.value)}
      />
      <button
        disabled={connectionStatus !== STATUS_CONNECTED}
        onClick={() => print(textToPrint)}
      >
        Print
      </button>

        <div>{error}</div>
    </div>
  );
};

export default ThermalPrinter;
